.page-about {
    .about {
        .banner__default {
            margin: 40px 0;

            img {
                // margin: 40px 0;
                display: block;
                width: 100%;
                height: 600px;
                min-height: 400px;
                border-radius: 6px;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    p {
        font-size: 16px;
        line-height: 25px;
        color: #666;
    }

    .mission {
        h2 {
            display: block;
            text-align: center;
            color: #297889;
            font-weight: 700;
            font-size: 32px;
        }

        .itens {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 30px;
            row-gap: 30px;
            padding: 30px 0;

            @include media (get-bp(tablet-portrait)) {
                grid-template-columns: 1fr 1fr;
            }

            @include media (get-bp(desktop)) {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    .block-mission {
        background-color: #f9e2d5;
        display: block;
        padding: 20px;
        border-radius: 10px;
        min-height: 210px;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: block;
                max-width: 40px;
                max-height: 40px;
                object-fit: cover;
                object-position: center;
            }

            i {
                font-size: 30px;
                margin-right: 20px;
                color: #007194;
            }

            h2 {
                color: #8cd2e7;
            }
        }

        h2 {
            font-size: 24px;
            display: contents;
        }

        p {
            font-size: 11px;
            margin-top: 20px;
        }
    }

    form {
        width: 100%;
        min-width: 370px;
        padding: 30px;

        button {
            margin-top: 20px;
        }
    }

    .galery {
        margin: 60px 0;

        img{
            display: block;
            border-radius: 6px;
        }

        .myGallery2 {
            height: 500px;
            width: 100%;
        }

        .myGallery {
            height: 20%;
            box-sizing: border-box;
            padding: 10px 0;

            .swiper-slide {
                width: 25%;
                height: 100%;
                opacity: 0.4;
            }

            .swiper-slide-thumb-active, .swiper-slide-active {
                opacity: 1;
            }

            img{
                display: block;
                height: 150px !important;
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        iframe {
            width: 100%;
            height: 100%;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .swiper-button-next, .swiper-button-prev {
            filter: invert(1);
        }
    }
}
