.barra-topo {
    background: get-color(primary);
    padding: 6px;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .lf {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 12px;

        i {
            margin-right: 10px;
        }

        .item {
            margin-right: 30px;
        }
    }
}