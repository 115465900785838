.projetos{
        padding: 40px 0;

    .titulo{
        p{
            font-size: 16px;
        }
    }

    .tab-group {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        font-size: 16px;
    }

    .tab-content {
        padding: 20px;
        border: none;
    }

    .tab {
        padding: 5px 20px;
        background-color: transparent;
        cursor: pointer;
        border: none;

        &.is-active{
            border-bottom: 2px solid get-color(primary);
        }
    }

    .content {
        display: none;
    }

    .content.is-active {
        display: block;
    }

    .items-projetos{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;

        @include media(get-bp(tablet-portrait)) {
            grid-template-columns: 1fr 1fr;
        }

        @include media(get-bp(desktop)) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .item-projeto{
        position: relative;
        border-radius: 4px;

        img{
            display: block;
            width: 100%;
            height: 350px;
            object-fit: cover;
            border-radius: 4px;
            overflow: hidden;
            object-position: top;
            transition: 3s;
        }

        &:hover,&.active{
            .hover-projetos{
                display: block !important;
            }
        }
    }

    .hover-projetos{
        display: none;
        position: absolute;
        background:linear-gradient(79deg, rgba(0, 0, 0, 0.8) 41%, rgba(17,17,17,0.06066176470588236) 90%);
        left: 20px;
        right: 20px;
        top: 20px;
        bottom: 20px;
        max-width: 350px;
        padding: 20px;
        overflow: hidden;
        transition: 3s;
        border-radius: 4px;
        box-shadow:1px 1px 5px #00000052;
    }

    .flag-projeto{
        position: absolute;
        top: 31px;
        right: -83px;
        background: #fff;
        width: 277px;
        height: 50px;
        transform: rotate(45deg);
        padding: 5px 0;
        display: none;
        &:hover{
            box-shadow: 1px 1px 5px #a79f9f;
        }

        img{
            display: block;
            width: 100%;
            object-fit: contain;
            height: 40px;
            object-position: center;
        }
    }

    .info-projeto {
        position: absolute;
        display: block;
        bottom: 20px;
        left: 20px;
        right: 20px;

        h2{
            font-size: 14px;
            display: -webkit-box;
            line-height: 16px;
            max-height: 32px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: get-color(light);
            text-transform:uppercase;
        }

        p{
            font-size: 12px;
            line-height: 13px;
            max-height: 26px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: get-color(light);
        }
    }
}

.modal-projeto{

    .modal__body{
        position: relative;
        overflow: hidden;
        max-height: 700px;
        overflow: auto;
    }

    .modal__close{
        svg{
            fill: #fff;
        }
    }

    .modal__header {
        background: #111;
    }

    .modal__content{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        overflow: hidden;

        @include media(get-bp(tablet-portrait)) {
            grid-template-columns: 1fr 1fr;
        }

        .infos {
            display: grid;

            i{
                color:get-color(primary);
                margin-right: 12px;

                &:last-child{
                    margin-right: 0;
                }
            }

            .icones{
                margin-right: 10px;
                font-size: 30px;
            }
        }

        .img{
            display: block;
            width: 400px;
            object-fit: cover;
            height: 390px;
            overflow: auto;
            border-radius: 0px;
            clip-path: polygon(0 7%, 100% 0%, 100% 93%, 0% 100%);

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: get-color(grey-lighter);
            }

            &::-webkit-scrollbar-thumb {
                background: get-color(primary);
                border-radius: 10px;
            }
        }

        img{
            display: block;
            width: 100%;
            object-fit: cover;
            border-radius: 0px;
        }

        .flag-projeto{
            position: absolute;
            top: 31px;
            right: -83px;
            background: #fff;
            width: 277px;
            height: 50px;
            transform: rotate(45deg);
            padding: 5px 0;
            box-shadow: 1px 1px 5px #a79f9f;

            img{
                display: block;
                width: 100%;
                object-fit: contain;
                height: 40px;
                object-position: center;
            }
        }

        h2{
            font-size: 20px;
            height: 50px;
            text-transform: uppercase;
        }

        p{
            font-size: 16px;
        }
    }
}
