.page-servico{
    .servicos-listings{
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 40px;

        @include media(get-bp(tablet-landscape)) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .item{
        i{
            font-size: 30px;
            color: get-color(secondary);
            margin-bottom: 20px;
        }

        h2{
            font-size: 20px;
            color: get-color(grey-light);
            font-weight: 400;
        }

        p{
            font-size: 14px;
            color: get-color(grey-light);
        }
    }

    .servicos-listings{
        padding: 40px 0;
    }
}
