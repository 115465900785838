.servicos{
    padding: 70px 0 160px 0;
    overflow: hidden;

    .container {
        position: relative;

        &::after {
            background-color: get-color(primary);
            content: " ";
            width: 2px;
            height: 830px;
            position: absolute;
            left: 0;
            top: -127px;
        }
    }

    .title{
        margin-bottom: 50px;

        h2{
            display: block;
            color: get-color(primary);
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 0;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 20px;

            @include media(get-bp(tablet-landscape)) {
                font-size: 64px;
                text-align: left;
            }
        }

        p{
            display: block;
            font-size: 14px;
            color: get-color(light);
            text-align: center;
            margin: 20px 0;

            @include media(get-bp(tablet-landscape)) {
                text-align: left;
            }
        }

        span{
            display: block;
            font-size: 14px;
            color: get-color(light);
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;

            @include media(get-bp(tablet-landscape)) {
                text-align: left;
            }
        }

        a {
            display: block;
            width: 100%;
            max-width: 150px;
            padding: 10px;
            margin-top: 30px;
            background-color: get-color(primary);
            border: 1px solid get-color(secondary);
            color: get-color(secondary);
            text-align: center;
            margin: auto;

            @include media(get-bp(tablet-landscape)) {
                margin-left:0 ;
            }

            &:hover{
                background-color: get-color(secondary);
                border: 1px solid get-color(secondary);
                color: get-color(primary);
            }
        }
    }

    .servicos-listing{
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 40px;

        @include media(get-bp(tablet-landscape)) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .item{
        clip-path: polygon(0 7%, 100% 0%, 100% 93%, 0% 100%);
        background-color: get-color(grey-lighter);
        display: grid;
        padding: 60px 30px;
        height: 380px;
        align-items: center;

        i{
            font-size: 30px;
            color: get-color(secondary);
            margin-bottom: 20px;
        }

        svg{
            display: block;
            margin: auto;
        }

        h2{
            font-size: 16px;
            color: get-color(dark);
            font-weight: 500;
            margin-bottom: 0;
            height: 40px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        p{
            font-size: 14px;
            color: get-color(grey-light);
            height: 106px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
        }
    }
}
