.mini-banner {
    background-color: get-color(gray);
    padding:18rem 0 5rem 0;

    @include media(get-bp(tablet-landscape)) {
        padding:20rem 0 5rem 0;
    }

    .container {
        margin-top: 0px !important  ;
    }

    h2 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        color: get-color(light);
        text-transform: uppercase;
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 0;

        @include media(get-bp(tablet-landscape)) {
            font-size: 60px;
            text-align: left;
        }

        small {
            font-size: 14px;
            color: get-color(light);
            text-transform: uppercase;
            margin-bottom: 16px;
            letter-spacing: 4px;
            font-family: $font-family-primary;
        }
    }

    .btn {
        background: #297889;
        border-radius: 32px;
        color: #fff;
        padding: 11px 20px;
        transition: 300ms;
        border: none;

        &:hover {
            background: #444;
        }
    }
}

// .modal-container {
//     height: 100vh;
//     width: 100%;
//     background: rgba(0, 0, 0, 0.5);
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 99;
//     display: none;
//     justify-content: center;
//     align-items: center;
// }

// .modal-container.ativo {
//     display: flex;
// }

// .modal {
//     background: #f9e2d5;
//     border: 6px solid #297889;
//     box-shadow: 0 0 6px 0px #404040;
//     position: relative;
//     border-radius: 8px;
// }

// iframe {
//     display: block;
// }

// @keyframes modal {
//     from {
//         opacity: 0;
//         transform: translate3d(0, -30px, 0);
//     }

//     to {
//         opacity: 1;
//         transform: translate3d(0, 0, 0);
//     }
// }

// .ativo .modal {
//     animation: modal .3s forwards;
// }

// .fechar {
//     width: 30px;
//     height: 29px;
//     border-radius: 50%;
//     border: 2px solid #9d0a0a;
//     top: -17px;
//     right: -15px;
//     position: absolute;
//     background: #E54;
//     box-shadow: 0 4px 4px 0 #444444;
//     font-size: 1rem;
//     color: #ffffff;
//     font-weight: bold;
//     cursor: pointer;
// }

// .modal label {
//     display: block;
//     font-weight: bold;
//     margin: 1rem 0 .5rem 0;
// }

// .modal input {
//     width: 100%;
//     padding: .7rem .5rem;
//     box-sizing: border-box;
// }

// .modal button[type="submit"] {
//     display: block;
//     background: #E54;
//     border: none;
//     border-radius: 4px;
//     padding: .7rem 2rem;
//     font-size: 1rem;
//     color: white;
//     margin-top: 1rem;
//     font-weight: bold;
// }
