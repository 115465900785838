.default-title {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 40px;

    @include media (get-bp(desktop)) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    p {
        color: get-color(light) !important;
        font-weight: 700;
        font-size: 25px;
        line-height: 1;
        margin: 0;
        font-family: $font-family-primary;
        letter-spacing: 0.08em;
        margin-bottom: 10px;

        &:last-child {
            text-transform: initial;
            margin-top: 15px;
            color: #666;
            font-weight: 400;
            line-height: 20px;

            @include media (get-bp(desktop)) {
                max-width: 70%;
            }
        }
    }

    span,strong {
        color: get-color(light);
        font-size: 12px;
        font-weight: 400;
    }

    h2 {
        color: get-color(light);
        font-size: 32px;
        font-weight: 600;
        margin-top: 16px;
        margin-bottom: 20px;
        line-height: 1;
        font-family: $font-family-primary;
    }
}
