.page-assessment {
    padding-top: 13rem;

    @include media(get-bp(desktop)) {
        padding-top: 14rem;
    }

    .assessments{
        padding: 60px 0;

        h2{
            font-size: 32px;
            margin-bottom: 40px;
        }
    }

    .assessment-items {
        background-color: get-color(grey-lighter);
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 30px;
        box-shadow: 0px 0px 7px #cecece;

        .content{
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 20px;

            @include media(get-bp(tablet-landscape)) {
                grid-template-columns: 1fr 230px;
                grid-column-gap: 40px;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            h2 {
                margin: 0;
                font-size: 16px;
                color: get-color(dark);
            }

            .period {
                color: get-color(grey);
                font-size: 14px;
            }
        }

        .details {
            margin-bottom: 20px;
            color: get-color(dark);

            h2{
                font-size: 18px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 46px;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 10px;
            }

            p{
                font-size: 14px;
            }
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 10px;

            button {
                padding: 10px 20px;
                border: none;
                border-radius: 4px;
                font-size: 14px;
            }

            .start-button {
                background-color: get-color(terciary);
                color: get-color(light);

                &.disabled {
                    opacity: 0.3;
                    cursor: no-drop;

                    &:hover {
                        background-color: get-color(terciary);
                    }
                }

                &:hover {
                    background-color: get-color(primary);
                }
            }

            .results-button {
                background-color: get-color(light);
                color: get-color(dark);
            }
        }
    }
}
