.solutions{
    padding-top: 40px;

    .container {
        position: relative;

        &::after {
            content: " ";
            width: 2px;
            height: 300px;
            background-color: get-color(primary);
            position: absolute;
            left: 0;
            top: -40px;
        }
    }

    .solutions__content{
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 30px;

        @include media(get-bp(tablet-landscape)) {
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 30px;
        }
    }

    .solutions__item{
        clip-path: polygon(0 7%, 100% 0%, 100% 93%, 0% 100%);
        background-color: get-color(primary);
        display: grid;
        padding: 20px 30px;
        align-items: center;

        img{
            display: block;
            width: 100%;
            height: 60px;
            object-fit: contain;
            object-position: center;
            filter: brightness(0) invert(1);
        }
    }
    .solutions__item {
        position: relative;
        overflow: hidden;
        background-color: get-color(primary);
        display: grid;
        padding: 20px 30px;
        align-items: center;
    }

    .solutions__item::before,
    .solutions__item::after {
        content: '';
        position: absolute;
        z-index: -1;
        transition: all 0.3s ease;
    }

    .solutions__item::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        clip-path: polygon(0 7%, 100% 0%, 100% 93%, 0% 100%);
    }

    .solutions__item::after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: get-color(grey);
        clip-path: polygon(0 7%, 100% 0%, 100% 93%, 0% 100%);
        opacity: 0;
    }

    .solutions__item:hover::after {
        opacity: 1;
    }
}
