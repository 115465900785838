.mobile-menu-is-open {
    // overflow-y: hidden;
}

.main-menu-mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background-color: get-color(light);
    color: get-color(light);
    width: 100%;
    max-width: 400px;
    padding: 56px 40px;
    overflow: auto;
    transform: translate3d(100%, 0, 0);
    background-color: rgba(get-color(dark), 0.9);
    display: flex;
    flex-direction: column;
    transition: 0.3s;
    backdrop-filter: blur(2px);

    .main-header__buttons{
        display: inherit;
        margin-bottom: 32px;

        a{
            font-size: 14px;
            text-transform: uppercase;
            margin-right: 10px;
            background-color: get-color(primary);
            border: none;

            &:last-child{
                margin-right: 0;
            }
        }
    }

    .menu-container__listing{
        display: block;
        margin-top: 32px;

        >li{
            margin-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .submenu{
                display: grid;
                visibility: hidden;
                opacity: 0;
                max-height: 0;
                padding: 0;
                margin: 0;
                width: 100%;
                list-style: none;
                row-gap: 16px;
                border-top: 1px solid rgba(get-color(primary), .1);
                transition: 400ms;
                background-color: rgba(255, 255, 255, 0.06);

                &::-webkit-scrollbar {
                    width: 5px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: get-color(primary);
                }
                &::-webkit-scrollbar-thumb:hover {
                    background-color: get-color(secondary);
                }
            }

            i{
                color: get-color(secondary);
            }

            &.is-active{
                .submenu{
                    padding: 16px;
                    margin: 10px 0 0 0;
                    visibility: visible;
                    opacity: 100%;
                    max-height: 200px;
                    overflow: auto;
                }
                .js-open-submenu-mobile{
                    transform: rotate(180deg);
                }
            }
        }
    }

    a{
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
    }

    .menu-item{
        margin-bottom: 20px;

        a{
            display: block;
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 400;
            text-transform: uppercase;
        }

        .menu-item__submenu{
            a{
                font-size: 18px;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }

    &.is-active {
        transform: translate3d(0, 0, 0);
    }
}

.mobile-menu__content {
    border-bottom: 1px solid get-color(grey-dark);
    padding-bottom: 18px;
    margin-bottom: 32px;
}

.mobile-logo-img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.main-menu-mobile{
    .main-menu-mobile.is-active{
        .burger-icon-container .is-active{
            margin-bottom: 32px;
        }
    }
}
