.share {
    margin: 60px 0;

    p {
        font-weight: 600;
        font-size: 14px;
        color: #444;
        margin-bottom: 20px;
    }
}

.share__links {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    a {
        background-color: #ededed;
        border-radius: 6px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;

        &+a {
            margin-left: 12px;
        }

        &:nth-child(1) {
            color: get-color(whatsapp);
        }

        &:nth-child(2) {
            color: get-color(facebook);
        }

        &:nth-child(3) {
            color: get-color(linkedIn);
        }

        &:nth-child(4) {
            color: get-color(link);
        }
    }
}
