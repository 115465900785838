.page-service {
    .our-solutions {
        margin: 48px 0;
        display: grid;
        grid-column-gap: 32px;

        @include media(get-bp(tablet-portrait)) {
            grid-template-columns: 1fr 1fr;
        }

        @include media(get-bp(desktop)) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
