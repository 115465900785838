.page-contact {
    .container {
        margin-top: 50px;

        .item-flex {
            @include media (get-bp(desktop)) {
                display: grid;
                grid-template-columns: 656px auto;
                grid-column-gap: 60px;
            }
        }
    }

    .contact-form {
        margin-bottom: 60px;

        @include media (get-bp(desktop)) {
            margin-bottom: 0;
            background-color: get-color(light);
            padding: 3rem;
            border-radius: 6px;
            margin-bottom: 50px;
        }

        .field {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            margin-bottom: 20px;

            &:focus-within {
                label {
                    color: get-color(primary);
                }

                input {
                    border: 1px solid get-color(primary);
                }

                textarea {
                    border: 1px solid get-color(primary);
                }
            }

            label {
                font-size: 13px;
                color: get-color(grey-light);
                margin-bottom: 6px;
            }

            span {
                width: 100%;
            }

            .wpcf7-list-item {
                margin: 0;
            }

            input {
                width: 100%;
                height: 40px;
                border-radius: 6px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                outline: none;
                padding: 0 1rem;
                font-size: 14px;
                color: get-color(dark);
            }

            textarea {
                width: 100%;
                height: 120px;
                border-radius: 6px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                resize: none;
                outline: none;
                padding: 1rem;
                font-size: 14px;
                color: get-color(dark);
            }

            input[type="checkbox"] {
                width: 16px;
                height: 16px;
                margin-right: 6px;
            }

            &.field--checkbox {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: row;

                label a {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }

        .submit {
            input {
                width: max-content;
                height: 40px;
                padding: 1rem 4rem;
                background-color: get-color(secondary);
                color: get-color(light);
                border: none;
                border-radius: 6px;
                letter-spacing: 2px;
                font-weight: 900;
                text-transform: uppercase;
                font-size: 16px;
                transition: 400ms;

                &:hover {
                    filter: brightness(0.9);
                }
            }
        }

        .two-columns {
            @include media (get-bp(tablet-portrait)) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
            }
        }

        .contact__file {
            width: 100%;
            height: 40px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            label {
                margin-left: auto;
                font-size: 12px;
                text-transform: uppercase;
                color: get-color(dark);
                font-weight: 700;
                background-color: get-color(grey-light);
                padding: 0.6rem 1rem;
                margin: 0 4px 0 auto;
                color: get-color(light);
                border-radius: 4px;

                &:hover {
                    cursor: pointer;
                    filter: brightness(0.9);
                }
            }

            span {
                margin-left: 10px;
                font-size: 14px;
                color: get-color(dark);
                font-weight: 400;
            }

            input[type=file] {
                display: none;
            }
        }
    }

    .info {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 60px;

        @include media (get-bp(desktop)) {
            margin-bottom: 0;
        }
    }

    .info__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;

        i {
            color: get-color(secondary);
            margin-right: 10px;
        }

        p {
            font-size: 14px;
            color: get-color(dark);
        }
    }

    .info__link {
        margin-top: 30px;

        a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: get-color(primary);
            font-weight: 900;
            text-transform: uppercase;
            font-size: 14px;
            font-family: $font-family-secondary;
            letter-spacing: 2px;

            i {
                color: get-color(secondary);
                margin-left: 10px;
                transition: 400ms;
            }

            &:hover {
                i {
                    margin-left: 14px;
                }
            }
        }
    }
}
