.footer-new{
    background-color: get-color(light);
    border-top: 1px solid get-color(grey-light);
    color: get-color(dark);
    position: relative;

    .footer__content{
        display: none;
        //display: grid;
        grid-row-gap: 20px;
        padding: 100px 0;

        position: relative;

        &::after {
            background-color: get-color(primary);
            content: " ";
            width: 2px;
            height: 472px;
            position: absolute;
            left: 0;
            top: 0px;
        }

        @include media(get-bp(desktop)){
            grid-row-gap: 50px;
            padding: 60px 0 38px 0;

            &::after {
                height: 257px;
            }
        }
    }

    .footer__row{
        display: grid;
        grid-row-gap: 20px;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include media(get-bp(desktop)){
            display: flex;
            align-items: center;
            justify-content: center;
        }
        p{
            text-align: center;
        }
    }

    .footer__logo{
        width: 80px;

        img{
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
        }

        color: get-color(light);
    }

    .footer__logo-last{
        display: flex;
        >img{
            margin-left: 32px;
        }
    }

    .social-listing{
        justify-content: left;

        .social-listing__item{
            margin-right: 20px;

            @include media(get-bp(desktop)){
                margin: 0;
                margin-left: 20px;
            }
        }

        i{
            color: get-color(dark);
        }
    }

    .menu-container__listing{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        @include media(get-bp(desktop)){
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 68px;
            grid-row-gap: 24px;
        }

        i{
            display: none;
        }

        a{
            img{
                display: none;
            }
        }
    }

    .footer__index{
        img{
            width: 100%;
            max-width: 45px;
            height: 45px;
            filter: brightness(0) invert(1);
            display: block;
            margin: 30px auto 0;

            @include media(get-bp(desktop)){
                margin-top: 0;
            }
        }
    }

    .submenu{
        display: none;
    }

    .footer__address{
        @include media(get-bp(desktop)){
            max-width: 562px;
            text-align: right;
        }

        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
    }

    .footer__contacts{
        display: grid;

        @include media(get-bp(desktop)){
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .footer__contact{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        i{
            margin-right: 10px;
        }

        &:last-child{
            margin-bottom: 0;
        }

        @include media(get-bp(desktop)){
            margin-left: 26px;
            margin-bottom: 0;
        }

        img{
            margin-right: 10px;
        }

        p{
            font-size: 14px;
        }
    }

    .footer__ornament{
        position: absolute;
        left: -20px;
        bottom: 180px;
        display: none;

        @include media(get-bp(desktop)){
            display: block;
        }
    }

    .footer__ornament2{
        position: absolute;
        right: 0;
        top: 90px;
        display: none;

        @include media(get-bp(desktop)){
            display: block;
        }
    }

    .social-listing{
        margin: 0;
        color: get-color(dark);
    }

    .footer__copy{
        background-color: get-color(dark);
        padding: 30px 0;
        color: get-color(light);
        text-align: center;

        >.container{
            @include media(get-bp(desktop)){
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            p{
                margin: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }

    .footer__cnpj{
        text-align: center;

        @include media(get-bp(desktop)){
            text-align: right;
        }

        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
        }
    }
}

.whatsapp_btns {
    position: fixed;
    bottom: 25px;
    right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 2;
  }

  .whatsapp_btns_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .whatsapp_btns_item span {
    display: inline-block;
    background-color: #25D064;
    margin-right: 5px;
    padding: 5px 10px;
    color: white;
    font-weight: 600;
    border-radius: 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    box-shadow: 1px 3px 7px rgba(35, 99, 58, 0.5);
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    display: none;
    z-index: 10;
  }

  .whatsapp_btns_item--button-zap {
    width: 48px;
    height: 51px;
    display: inline-block;
    background: #25D064;
    text-align: center;
    border-radius: 50%;
    margin: 5px 0;
    z-index: 5;
    position: relative;
  }

  .whatsapp_btns_item--button-zap svg {
    width: 32px;
    height: 28px;
    position: relative;
    top: 50%;
    left: 0%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10;


    &:hover~span {
        display: block;
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
  }

  .product--service__whatsapp__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    width: 100%;
    max-width: 330px;
  }

  .product--service__whatsapp__list__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 10px;
  }

  .product--service__whatsapp__list__item:last-of-type {
    margin-right: 0;
  }

  .whatsapp_btns_item--button-zap:hover~span {
    display: block;
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  /*.whatsapp_btns_item .whatsapp_btns_item--button-zap::after{
 border: 17px solid #25d064;
    border-radius: 30px;
    bottom: 0px;
    top: 0px;
    left: -7px;
    content: " ";
    right: -7px;
    position: absolute;
    animation: is-ripe 2.1s infinite;
} */

  .whatsapp_btns_item .whatsapp_btns_item--button-zap::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #25d064;
    opacity: 0.1;
    /*-webkit-animation: zoomtwx 1.6s 15 ease-in;
    animation: zoomtwx 1.6s 15 ease-in;*/
    -webkit-animation:  zoomtwx 1.6s infinite;
  animation:  zoomtwx 1.6s infinite;
  }

  @keyframes is-ripe {
    to {
      opacity: 0;
      transform: scale(1.15, 1.5);
    }
  }

  @keyframes zoomtwx {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.2;
    }

    100% {
      transform: scale(1.8);
      opacity: 0;
    }
  }

  .modal-wpp{
    z-index: 999999;

    .formulario {
        padding: 20px 0;
        margin: 0;
        background: get-color(light)
    }

    .modal__body{
        position: relative;
        overflow: hidden;
        max-height: 700px;
        overflow: auto;
    }

    .modal__close{
        svg{
            fill: #fff;
        }
    }

    .modal__header {
        background: get-color(dark)
    }
  }
