.clientes{
    padding: 160px 0 160px 0;
    background-color: get-color(primary);
    clip-path: polygon(0 5%, 100% 0%, 100% 100%, 0% 100%);

    .title{
        margin-bottom: 50px;

        h2{
            display: block;
            color: get-color(light);
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 0;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 20px;
            margin-bottom: 110px;

            @include media(get-bp(tablet-landscape)) {
                font-size: 64px;
            }
        }

        p{
            display: block;
            font-size: 14px;
            color: get-color(light);
            text-align: center;
            margin: 20px 0;

            @include media(get-bp(tablet-landscape)) {
                text-align: left;
            }
        }

        span{
            display: block;
            font-size: 14px;
            color: get-color(light);
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;

            @include media(get-bp(tablet-landscape)) {
                text-align: left;
            }
        }

        a {
            display: block;
            width: 100%;
            max-width: 150px;
            padding: 10px;
            margin-top: 30px;
            background-color: get-color(primary);
            border: 1px solid get-color(secondary);
            color: get-color(secondary);
            text-align: center;
            margin: auto;

            @include media(get-bp(tablet-landscape)) {
                margin-left:0 ;
            }

            &:hover{
                background-color: get-color(secondary);
                border: 1px solid get-color(secondary);
                color: get-color(primary);
            }
        }
    }

    .clientes-listing{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 40px;

        @include media(get-bp(tablet-landscape)) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .item{
        img {
            margin: auto;
            display: block;
            width: 170px;
            height: 70px;
            object-fit: contain;
            filter: brightness(0) invert(1);
            opacity: 0.9;

            &:hover{
                filter: brightness(1) invert(0);
            }
        }
    }
}
