.depositions-time {
    padding: 160px 0;
    background-color: get-color(primary);

    .swiper-button-next.dp,
    .swiper-button-prev.dp {
        background: unset;
        color: #fff;
    }

    .deposition {
        padding: 0 20px;
        .img{
            filter: contrast(0);
        }

        .author-info {
            h2 {
                display: block;
                color: get-color(gray);
                font-size: 20px;
                font-weight: 400;
                margin-bottom: 0;
                text-align: left;
                text-transform: uppercase;
            }

            p {
                display: block;
                font-size: 14px;
                color: get-color(light);
                max-width: 700px;
                text-align: left;
                margin: auto;
                height: inherit;
            }
        }

        p {
            display: block;
            font-size: 14px;
            color: rgba(get-color(light), 0.9);
            max-width: 700px;
            text-align: left;
            margin: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            height: 80px;
            -webkit-box-orient: vertical;
        }

        .deposition-author {
            display: flex;
            align-items: center;
            margin-top: 30px;

            img {
                display: block;
                width: 100%;
                max-width: 70px;
                height: 70px;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
                margin-right: 15px;
            }
        }
    }

    .title{
        padding-bottom: 70px;

        @include media(get-bp(tablet-landscape)) {
            padding-bottom: 120px;
        }

        h2{
            display: block;
            color: get-color(light);
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 0;
            text-align: center;
            text-transform: uppercase;

            @include media(get-bp(tablet-landscape)) {
                font-size: 64px;
                text-align: left;
            }
        }
    }

    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 70%;
    }
}
