.page-about-news{
    .about{
        padding-top: 12rem;
        background-color: get-color(grey);

        @include media(get-bp(tablet-portrait)) {
            padding-top: 19rem;
        }
    }

    .title {
        margin-bottom: 30px;

        p{
            font-size: 16px;
            font-weight: 300;
            color: get-color(dark);
            margin-bottom: 0;

            &:first-child{
                text-transform: uppercase;
                padding-left: 60px;
                color: get-color(secondary);
                font-weight: 500;

                &::after{
                    width: 50px;
                    height: 2px;
                    background-color: get-color(secondary);
                    content: '';
                    display: block;
                    margin-top: -13px;
                    margin-left: -60px;
                }
            }
        }

        img{
            display: block;
            margin-top: 32px;

            @include media(get-bp(tablet-portrait)) {
                margin-top: 72px;
                width: 550px;
                height: 611px;
                display: block;
                object-fit: cover;
                border-radius: 4px;
            }
        }

        h2{
            font-size: 32px;
            font-weight: 500;
            margin-top: 10px;

            @include media(get-bp(tablet-portrait)) {
                font-size: 48px;
                margin-bottom: 10px;
            }
        }
    }

    .description{
        @include media(get-bp(desktop)) {
            height: 325px;
            overflow: auto;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: get-color(grey-light);
        }

        &::-webkit-scrollbar-thumb {
            background: get-color(primary);
            border-radius: 10px;
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 140%;
            letter-spacing: 0.24px;
        }
    }

    p{
        font-size: 14px;
        color: get-color(grey);
    }

    .content-header {
        padding-bottom: 40px;

        @include media(get-bp(desktop)) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 60px;
            height: 400px;
        }
    }

    .qualifications{
        padding: 60px 0;
        float: left;

        @include media(get-bp(desktop)) {
            max-width: 530px;
            float: right;
            height: 420px;
        }

        .title{
            margin-bottom: 50px;

            p{
                font-size: 14x;
                font-weight: 300;
                color: get-color(dark);
                margin-bottom: 0;

                &:first-child{
                    text-transform: uppercase;
                    padding-left: 60px;
                    color: get-color(secondary);
                    font-weight: 500;

                    &::after{
                        width: 40px;
                        height: 2px;
                        background-color: get-color(secondary);
                        content: '';
                        display: block;
                        margin-top: -13px;
                        margin-left: -55px;
                    }
                }
            }
        }

        ul{
            padding-left: 20px;

            @include media(get-bp(desktop)) {
                height: 265px;
                overflow: auto;
            }

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: get-color(grey-light);
            }

            &::-webkit-scrollbar-thumb {
                background: get-color(primary);
                border-radius: 10px;
            }
        }

        li{
            margin-bottom: 30px;

            &:last-child{
                margin-bottom: 0;
            }

            &::marker{
                color: get-color(secondary);
            }

            p{
                font-size: 14px;
                font-weight: 300;
                color: get-color(dark);
            }

            span{
                color: get-color(grey);
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    .academic-background{
        clear: both;
        padding: 0px 0 40px 0;

        @include media(get-bp(desktop)) {
            padding: 56px 0 80px 0;
        }

        .academic-cards{
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 24px;

            @include media(get-bp(desktop)) {
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 60px;
            }
        }

        .academic-card {
            display: flex;
            align-items: center;

            img{
                display: block;
                width: 64px;
                height: 42px;
                object-fit: contain;
            }

            .image{
                padding: 18px 8px;
                background: get-color(grey);
                border-radius: 5px;
                margin-right: 20px;
                width: 80px;
                height: 80px;
            }

            .text{
                p{
                    font-size: 14px;
                    font-weight: 300;
                    color: get-color(dark);
                    margin-bottom: 8px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    height: 41px;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 8px;
                    }

                    &::-webkit-scrollbar-track {
                        background: get-color(grey-light);
                    }

                    &::-webkit-scrollbar-thumb {
                        background: get-color(primary);
                        border-radius: 10px;
                    }
                }

                span{
                    color: get-color(grey);
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }

    .action{
        padding: 77px 0;

        .content{
            display: flex;
            flex-direction: column-reverse;

            @include media(get-bp(desktop)) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 60px;
            }

            .image{
                display: block;
                margin-bottom: 40px;

                @include media(get-bp(desktop)) {
                    margin-bottom: 0;
                }
            }

            .text{
                .title{
                    margin-bottom: 50px;

                    p{
                        font-size: 14x;
                        font-weight: 300;
                        color: get-color(dark);
                        margin-bottom: 0;

                        &:first-child{
                            text-transform: uppercase;
                            padding-left: 60px;
                            color: get-color(secondary);
                            font-weight: 500;

                            &::after{
                                width: 40px;
                                height: 2px;
                                background-color: get-color(secondary);
                                content: '';
                                display: block;
                                margin-top: -13px;
                                margin-left: -55px;
                            }
                        }
                    }
                }

                ul{
                    padding-left: 20px;

                    @include media(get-bp(desktop)) {
                        height: 241px;
                        overflow: auto;
                    }

                    &::-webkit-scrollbar {
                        width: 8px;
                    }

                    &::-webkit-scrollbar-track {
                        background: get-color(grey-light);
                    }

                    &::-webkit-scrollbar-thumb {
                        background: get-color(primary);
                        border-radius: 10px;
                    }
                }

                li{
                    margin-bottom: 20px;

                    &:last-child{
                        margin-bottom: 0;
                    }

                    &::marker{
                        color: get-color(secondary);
                    }

                    p{
                        font-size: 14px;
                        font-weight: 300;
                        color: get-color(dark);
                    }

                    span{
                        color: get-color(grey);
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }

        .btn {
            display: inline-block;
            color: get-color(dark);
            border-radius: 4px;
            margin-top: 2px;
            border: 1px solid get-color(secondary);
            padding: 10px;

            img {
                display: block;
                width: 10px;
                height: 20px;
                margin-left: 15px;
                object-fit: cover;
            }

            p{
                margin: 0;
                font-size: 14px;
                height: inherit;
                text-transform: uppercase;
                color: get-color(dark);
            }

            i {
                transform: rotate(-45deg);
                margin-left: 10px;
                transition: 400ms;
                color: get-color(secondary);
            }
        }

        .image{
            img{
                display: block;
                width: 100%;
                height: 368px;
                object-fit: cover;
                border-radius: 4px;
            }
        }
    }
}
