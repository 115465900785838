.partners {
    background: #f0a850;
    padding: 50px 0;

    .partners-slide {
        img {
            max-width: 70px;
            max-height: 60px;
            object-fit: contain;
            object-position: center;
            display: block;
            margin: auto;
            filter: brightness(0) invert(1);
        }

        .swiper-wrapper {
            align-items: center;
        }

        .swiper-button-next.pa,
        .swiper-button-prev.pa {
            background: unset;
            color: #fff;
        }
    }
}
