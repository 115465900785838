.time {
    padding: 70px 0;

    .btn{
        display: block;
        width: 100%;
        max-width: 150px;
        padding: 10px;
        margin-top: 30px;
        background-color: transparent;
        border: 1px solid get-color(primary);
        color: get-color(primary);
        text-align: center;
        margin: 40px auto 0 ;

        &:hover{
            background-color: get-color(secondary);
            border: 1px solid get-color(secondary);
            color: get-color(primary);
        }
    }

    .title {
        padding-bottom: 70px;

        h2 {
            display: block;
            color: get-color(secondary);
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 0;
            text-align: center;
        }

        p {
            display: block;
            font-size: 14px;
            color: get-color(grey-light);
            max-width: 700px;
            text-align: center;
            margin: auto;
        }

        span {
            display: block;
            font-size: 14px;
            color: get-color(grey-light);
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
        }
    }

    .time-listing {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 40px;

        @include media(get-bp(tablet-landscape)) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .item {
        position: relative;
        cursor: pointer;
        border-radius: 4px;

        img {
            display: block;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            position: relative;
            min-height: 370px;
            max-height: 330px;
            filter: grayscale(0.5);
            border-radius: 4px;
        }

        p {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
            display: block;
            background: linear-gradient(180deg, rgba(33, 33, 33, 0.00) 0%, #212121 94.27%);

            span {
                position: absolute;
                z-index: 1;
                color: get-color(light);
                left: 32px;
                right: 0;
                bottom: 32px;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 28.8px;
            }
        }

        &:hover {
            transition: 400ms;
            border-radius: 10px;

            span{
                display: none;
            }

            .hover {
                display: flex;
                align-items: center;

                span{
                    display: block;
                }
            }
        }
    }

    .hover {
        background: rgba(get-color(primary), 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        display: none;
        z-index: 6;
        left: 0;
        right: 0;
        padding: 25px;
        transition: 400ms;
        border-radius: 4px;

        h2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 28.8px;
            text-align: inherit;
            color: get-color(secondary);
            height: 60px;
            overflow: hidden;
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20.8px;
            background: none;
            position: relative;
            color: rgba(255, 255, 255, 0.80);
            margin-top: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            height: 78px;
            overflow: hidden;
        }

        span {
            color: get-color(secondary);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.2px;
            text-decoration-line: underline;
            margin-top: 40px;
            display: block;
            width: 150px;
            background: transparents;
        }
    }
}

.modal-speakers{
    img {
        display: block;
        width: 150px;
        height: 150px;
        border-radius: 10px;
        object-fit: cover;
    }

    h2{
        text-align: start;
        margin-top: 32px;
        color: get-color(dark);
        position: relative;

        &::after{
            content: "";
            width: 40px;
            height: 3px;
            border-radius: 100px;
            background-color:get-color(secondary);
            bottom: -24px;
            left: 0;
            position: absolute;
        }
    }

    p{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.4px;
        margin-top: 50px;
        max-height: 400px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: get-color(grey-lighter);
        }

        &::-webkit-scrollbar-thumb {
            background: get-color(primary);
            border-radius: 10px;
        }
    }

    .modal__box {
        max-width: 48rem;
    }

    .modal__close {
        width: 2.5rem;
        margin-left: 2rem;
        cursor: pointer;
        position: absolute;
        z-index: 999;
        right: 30px;
    }

    .modal__content {
        overflow: initial;
    }

    .modal__body{
        padding: 3rem 2rem 4rem 2rem;
    }
}

.modal{
    z-index: 999;
}
