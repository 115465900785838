body:has(.formulario) .news{
    margin: 0;
}

.formulario{
    padding: 80px 0;
    margin-top: 50px;
    background: #fa7f4b0f;

    .container{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;

        @include media(get-bp(tablet-portrait)) {
            grid-template-columns: 1fr 1fr;
            column-gap: 80px;
            align-items: center;
        }
    }

    input.button{
        margin-top: 30px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            border-radius: 4px;
            width: 100%;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: get-color(secondary);
            opacity: 0.1;
            -webkit-animation:  zoomtwx 1.6s infinite;
            animation:  zoomtwx 1.6s infinite;
          }

        &:hover{
            background: get-color(secondary);
        }
    }

    .infos{
        h2{
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 300;

            @include media(get-bp(tablet-portrait)) {
                font-size: 32px;
            }
        }
        p{
            font-size: 14px;
            line-height: 27px;
        }
    }
}
