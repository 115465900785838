.page-time{
    .time-listings {
        padding: 50px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 40px;

        @include media(get-bp(tablet-landscape)) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .item {
        position: relative;
        cursor: pointer;
        border-radius: 4px;

        img {
            display: block;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            position: relative;
            min-height: 370px;
            max-height: 330px;
            filter: grayscale(0.5);
            border-radius: 4px;
        }

        p {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
            display: block;
            background: linear-gradient(180deg, rgba(33, 33, 33, 0.00) 0%, #212121 94.27%);

            span {
                position: absolute;
                z-index: 1;
                color: get-color(light);
                left: 32px;
                right: 0;
                bottom: 32px;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 28.8px;
            }
        }

        &:hover {
            transition: 400ms;
            border-radius: 10px;

            span{
                display: none;
            }

            .hover {
                display: flex;
                align-items: center;

                span{
                    display: block;
                }
            }
        }
    }

    .hover {
        background: rgba(get-color(primary), 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        display: none;
        z-index: 6;
        left: 0;
        right: 0;
        padding: 25px;
        transition: 400ms;
        border-radius: 4px;

        h2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 28.8px;
            text-align: inherit;
            color: get-color(secondary);
            height: 60px;
            overflow: hidden;
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20.8px;
            background: none;
            position: relative;
            color: rgba(255, 255, 255, 0.80);
            margin-top: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            height: 78px;
            overflow: hidden;
        }

        span {
            color: get-color(secondary);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.2px;
            text-decoration-line: underline;
            margin-top: 40px;
            display: block;
            width: 150px;
            background: transparents;
        }
    }
}
