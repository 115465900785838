.page-home {
    padding-top: 13rem;

    @include media(get-bp(desktop)) {
        padding-top: 14rem;
    }

    .page-wrapper.content-wrapper {

        @include media(get-bp(desktop)) {
            margin-top: 90px;
        }
    }

    .login{
        display: grid;
        grid-template-columns: 1fr;

        @include media(get-bp(tablet-landscape)) {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 60px;
            align-items: center;
        }

        .block-lft{
            img{
                display: block;
                height: 620px;
                width: 100%;
                object-fit: cover;
            }
        }

        .block-rgt{
            position: relative;

            .message-box {
                position: absolute;
                top: -200px;
                width: 100%;
                left: 0;

                @include media(get-bp(desktop)) {
                    top: -110px;
                }
            }

            padding: 30px 30px 70px;
            background: get-color(light);
            margin-top: -240px;
            border-radius: 30px 30px 0px 0;
            transition: 400ms;

            @include media(get-bp(desktop)) {
                 padding: 0 40px 0 0px;
                 margin-top: unset;
                 border-radius: 0;
                 max-width: 600px;
            }

            h1{
                color: get-color(dark);
                font-size: 42px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            h2{
                color: get-color(dark);
                font-size: 32px;
                font-weight: 600;
                margin-top: 16px;
                margin-bottom: 40px;
                line-height: 1;
            }

            input[type="submit"] {
                background: get-color(terciary);
                border: none;
                border-radius: 4px;
                width: 100%;
                text-align: center;
                color: get-color(light);
                text-transform: uppercase;
                padding: 12px;
                font-weight: 700;
            }
        }
    }
}
