.timeline{
    position: relative;
}

.timeline__wire{
    position: absolute;
    top: -65px;

    img{
        width: 111px;
        height: 106px;
        object-fit: contain;
        display: block;
    }
}

.timeline__content{
    background-color: get-color(grey);
    padding: 74px 0;
    overflow: hidden;
}

.timeline__title{
    color: get-color(light);
    text-align: center;
    padding: 0 30px;
    margin-bottom: 80px;

    h2{
        margin: 0;

    }
}

.timeline__mobile{
    padding-left: 30px;

    @include media(get-bp(desktop)){
        display: none;
    }
}

.timeline__item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
    border-left: 1px solid get-color(primary);

    p{
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: get-color(light);
        background-color: get-color(primary);
        padding: 24px;
        max-height: 220px;
        overflow: scroll;
        margin-right: 60px;
    }

    span{
        background-color: get-color(secondary);
        display: block;
        width: 100%;
        margin-top: auto;
        color: get-color(light);
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        padding: 22px;
    }
}

.timeline__scroll{
    display: none;

    p{
        text-align: center;
    }

    @include media(get-bp(desktop)){
        display: block;
        padding: 0 30px;
        color: get-color(light);
        font-size: 32px;
        margin-top: 50px;
        animation: text 2s infinite;
    }
}

@keyframes text {
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
}

.timeline__desktop{
    display: none;
    padding: 0 80px;
    overflow: auto;
    height: 600px;
    position: relative;

    &::-webkit-scrollbar {
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: get-color(secondary);
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: get-color(primary);
    }

    @include media(get-bp(desktop)){
        display: block;
    }
}

.timeline__items{
    display: flex;
    align-items: center;
    width: max-content;
}

.timeline__box{
    position: absolute;
    width: 384px;
    height: 170px;
    display: flex;
    align-items: center;
    background-color: get-color(secondary);
    padding: 24px;
    border-radius: 6px;
    top: 0;
    z-index: 2;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: get-color(secondary);
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: get-color(secondary);
    }

    p{
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: get-color(light);
        text-align: center;
    }
}

.timeline__data{
    width: 384px;

    &:nth-child(even){
        .timeline__box{
            top: 410px;
        }

        span{
            &::after{
                top: unset;
                bottom: -150px;
            }

            &::before{
                top: unset;
                bottom: -3px;
            }
        }
    }

    &:first-child{
        span{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }

    &:last-child{
        span{
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    span{
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: get-color(light);
        background-color: get-color(secondary);
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 245px;
        position: relative;

        &::after{
            content: "";
            width: 2px;
            height: 150px;
            display: block;
            background-color: get-color(secondary);
            position: absolute;
            top: -150px;
        }

        &::before{
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            display: block;
            background-color: get-color(primary);
            position: absolute;
            top: -3px;
        }
    }
}
