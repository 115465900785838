//posicionamento do menu fixo no topo
.main-header-bundle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
}

.main-header {
    background-color: get-color(terciary);
    color: get-color(dark);
    transition: 400ms;

    h1 {
        margin: 0;
    }
    &.change-color {
        background-color: rgba(17, 17, 17, 0.5);
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.1);

        .languages__options {
            background-color: rgba(17, 17, 17, 0.9);
        }

        .main-header__logo-container {
            width: 80px;

            a {
                display: none;

                &:last-child {
                    display: flex;
                }
            }
        }

        .menu-button-1 {
            filter: brightness(0) invert(1);
        }
    }
}

.menu-buttons {
    @include media(get-bp(desktop)) {
        display: flex;
        align-self: center;
    }
    a {
        padding: 5px 15px;
        display: block;
        background-color: get-color(primary);
        margin-bottom: 20px;
        text-align: center;
        border-radius: 5px;
        color: get-color(secondary);
        font-weight: 600;
        transition: 400ms;

        @include media(get-bp(desktop)) {
            margin-right: 20px;
            margin-bottom: 0;
        }
        &:hover {
            background-color: get-color(secondary);
            color: get-color(light);
        }
    }
}

//menu desktop
.desktop-menu {
    display: none;

    @media (min-width: 1200px) {
        display: block;

        .container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;

            p{
                text-transform: uppercase;
                color: get-color(light);
            }

            .right {
                display: flex;
                align-items: center;
            }

            .reserv {
                padding: 11px;
                font-family: $font-family-secondary;
                color: get-color(light);
                text-transform: uppercase;
                font-size: 14px;
                letter-spacing: 1.2px;
                background-color: get-color(primary);
                width: max-content;
                margin-left: auto;
                transition: 400ms;
                border-radius: 4px;
                margin-right: 20px;
               border: 1px solid get-color(primary);

                &:hover {
                    color: get-color(dark);
                    background-color: get-color(dark);
                }
            }

            .main-header__buttons {
                width: max-content;
                margin-left: auto;
                display: flex;
                align-items: flex-start;
            }

            .languages {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                width: 70px;
                height: 38px;
                border-radius: 4px;
                padding: 0.05rem 0.5rem 0.6rem;
                margin-left: 10px;
                position: relative;
                transition: 400ms;

                &:hover {
                    cursor: pointer;
                }

                &::before {
                    content: "\f078";
                    font-family: "fontAwesome";
                    font-size: 14px;
                    color: get-color(light);
                    position: absolute;
                    top: 8px;
                    right: 4px;
                    transition: 400ms;
                }

                &.active {
                    &::before {
                        transform: rotate(-180deg);
                    }

                    .languages__options {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .languages__options {
                    position: absolute;
                    width: 100%;
                    height: max-content;
                    left: 0;
                    top: 38px;
                    opacity: 0;
                    visibility: hidden;
                    padding-left: 0.5rem;
                    display: block;
                    transition: 200ms;
                }

                div,
                a {
                    width: 36px;
                    height: 36px;
                    display: block;
                    user-select: none;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    .menu-content {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 30%;
        background-color: rgba(0, 0, 0, 0.8);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.8px);
        -webkit-backdrop-filter: blur(8px);
        transform: translateX(100%);
        transition: 400ms;

        &.is-active {
            transform: translateX(0%);
        }

        .languages {
            display: none;
        }
    }

    .close-menu {
        width: 20px;
        margin-left: auto;

        &:hover {
            cursor: pointer;
        }
    }

    .main-header__logo-container {
        width: 80px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin: auto;

        a {
            display: flex;

            &:last-child {
                display: none;

                img {
                    filter: none;
                }
            }
        }
    }

    .main-header__logo {
        width: 100%;
        height: 100%;
        display: inline-block;
        object-fit: contain;
    }

    .header-content {
        display: flex;
        align-items: center;
    }

    .social-listing {
        margin-left: 20px;
        .social-listing__item {
            color: get-color(light);
        }
    }

    .menu-container {
        flex: 1;
        padding: 4rem;

        button {
            display: none;
        }

        @include media(get-bp(desktop)) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
        }

        .menu-container__listing {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;

            > li {
                position: relative;

                > .submenu {
                    display: grid;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    bottom: 0;
                    transform: translateY(100%);
                    position: absolute;
                    background-color: get-color(grey);
                    color: get-color(light);
                    opacity: 0;
                    visibility: hidden;
                    max-height: 200px;
                    width: 200px;
                    padding: 10px;
                    row-gap: 10px;
                    overflow: auto;
                    transition: 400ms;

                    li:hover {
                        text-decoration: underline;
                        color: get-color(primary);
                    }
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: get-color(grey-light);
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background-color: get-color(grey-dark);
                    }
                }
                &:hover {
                    > .submenu {
                        visibility: visible;
                        opacity: 100%;
                    }
                }
            }
            > li {
                transition: 400ms;

                @include media(get-bp(desktop)) {
                    margin-bottom: 24px;
                    display: flex;
                    font-family: $font-family-secondary;
                    letter-spacing: 1.4px;
                    font-size: 16px;
                    font-weight: 700;
                }
                &:hover {
                    color: get-color(primary);
                }
            }
            a {
                align-self: center;
                font-weight: 400;
                height: 100%;
                display: flex;
                align-items: center;
                letter-spacing: 3px;
                font-size: 18px;
                font-weight: 400;
                text-transform: uppercase;
            }
        }

        .menu-item {
            margin-left: 30px;
            padding-bottom: 35px;
            margin-top: 40px;
            border-bottom: 3px solid transparent;
            position: relative;
            &:hover {
                text-decoration: underline;
                .menu-item__submenu {
                    display: block;
                }
            }
        }
        .menu-item__submenu {
            position: absolute;
            left: 0;
            width: 130px;
            font-size: 14px;
            display: none;
            flex-wrap: wrap;
            z-index: 1;
            top: 60px;
            margin: 0;
            a {
                font-size: 12px;
                background-color: get-color(primary);
                padding: 10px;
                display: block;
                width: 100%;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .burger-icon-container {
        &.is-active {
            .burger-icon {
                background-color: transparent;
            }
        }
        .burger-icon {
            background-color: get-color(light);

            &::before {
                background-color: get-color(light);
            }

            &::after {
                background-color: get-color(light);
            }
        }
    }
}

//menu mobile
.mobile-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    @media (min-width: 1200px) {
        display: none;
    }

    .mobile-logo {
        width: 120px;

        a {
            display: flex;
            margin: 10px 0;
        }
        .main-header__mobile-controls{
            .burger-icon-container{
                margin-bottom: 0;
            }
        }
    }

    //controle da cor do burger menu (acesso facil)
    .burger-icon {
        background-color: get-color(light);
        &::before {
            background-color: get-color(light);
        }

        &::after {
            background-color: get-color(light);
        }
    }
}

//espacamento do conteudo pra barra do topo
.main-header-clearance {
    padding-top: var(--main-header-height);
    display: none;
}

.js-open-submenu-mobile {
    display: flex;
    align-items: center;
    transition: 400ms;
}

.main-menu-mobile__footer{
    .items{
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &:last-child{
            margin-bottom: 0;
        }

        .icon {
            background-color: rgba(255, 255, 255, 0.06);
            padding: 9px 10px 0px 10px;
            margin-right: 6px;
            border-radius: 5px;
        }

        p{
            font-size: 14px;
        }
    }
}


.main-menu-mobile{
    .social-listing {
        display: flex;
        justify-content: left;
        margin-top: 40px;
    }

    .social-listing__item{
        display: block;
        font-size: 20px;
        margin-left: 5px;
        margin-right: 5px;
        transition: 400ms;

        &:hover{
            transform: rotate(10deg);
        }

        i{
            background-color: get-color(primary);
            padding:  10px;
            color: get-color(light);
            border-radius: 5px;

            .fab.fa-facebook-f {
                padding: 10px 12px;
            }
        }

        i.fab.fa-facebook-f{
            padding: 10px 14px;
        }
    }
}

.main-header__logo.logo-1 {
    display: none;
}

.menu-button-1 {
    display: none;
}

.menu-close {
    display: block;
}
