.about-new{
    padding: 80px 0 0 0;

    @include media(get-bp(tablet-landscape)) {
        padding: 80px 0;
    }

    .title{
        h2{
            display: block;
            color: get-color(primary);
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 0;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 20px;

            @include media(get-bp(tablet-landscape)) {
                font-size: 64px;
                text-align: left;
            }
        }

        p{
            display: block;
            font-size: 14px;
            color: get-color(light);
            text-align: center;
            margin: 20px 0;

            @include media(get-bp(tablet-landscape)) {
                text-align: left;
            }
        }

        span{
            display: block;
            font-size: 14px;
            color: get-color(light);
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;

            @include media(get-bp(tablet-landscape)) {
                text-align: left;
            }
        }

        a {
            display: block;
            width: 100%;
            max-width: 150px;
            padding: 10px;
            margin-top: 30px;
            background-color: get-color(primary);
            border: 1px solid get-color(secondary);
            color: get-color(secondary);
            text-align: center;
            margin: auto;

            @include media(get-bp(tablet-landscape)) {
                margin-left:0 ;
            }

            &:hover{
                background-color: get-color(secondary);
                border: 1px solid get-color(secondary);
                color: get-color(primary);
            }
        }
    }

    .container{
        img {
            display: block;
            width: 100%;
            height: 470px;
            object-fit: contain;
        }

        position: relative;

        &::after {
            background-color: get-color(primary);
            content: " ";
            width: 2px;
            height: 830px;
            position: absolute;
            left: 0;
            top: -87px;
        }
    }

    .btn {
        display: flex;
        align-items: center;
        background: get-color(light);
        width: 200px;
        justify-content: left;
        color: get-color(dark);
        border-radius: 4px;
        margin-top: 20px;
        list-style: 17px;

        @include media(get-bp(desktop)) {
            margin-top: 30px;
        }

        p{
            margin: 0;
            font-size: 16px;
            text-transform: uppercase;
        }

        i {
            transform: rotate(-45deg);
            margin-left: 10px;
            transition: 400ms;
            color: get-color(dark);
        }
    }

    .about-items{
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 40px;

        @include media(get-bp(tablet-landscape)) {
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
        }
    }
}
