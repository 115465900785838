.menu-filter {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 12px;
    row-gap: 20px;

    @include media(get-bp(desktop)) {
        grid-template-columns: 1fr 1fr 1fr 1fr 200px;
        column-gap: 16px;
        row-gap: 0px;
    }

    &.ref{
        @include media(get-bp(desktop)) {
            grid-template-columns: 1fr 1fr 1fr 200px;
            column-gap: 16px;
            row-gap: 0px;
        }
        @include media(get-bp(desktop)) {
            .field--submit {
                grid-area: 1 / 4 / 2 / 5;
            }
        }
    }

    .navigation-desktop {
        display: none;

        @include media (get-bp(desktop)) {
            display: block;
        }
    }

    .navigation-mobile {
        @include media (get-bp(desktop)) {
            // display: none;
        }

        .field {
            padding: 1rem 0;

            input {
                width: 100%;
                height: 40px;
                padding: 0 1rem;
                outline: none;
                font-size: 14px;
            }

            label {
                text-align: left;
                display: block;
                text-transform: uppercase;
                font-size: 14px;
            }
        }
    }

    .menu-toggle-button {
        width: 100%;
        text-transform: uppercase;
        border: 1px solid get-color(primary);
        color: get-color(primary);
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        outline: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-multi-select-selection {
            color: get-color(dark) !important;
            font-size: 12px;
            text-align: left;
            max-height: 40px;
            overflow: auto;
        }
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        background-color: get-color(light);
        border: 1px solid get-color( primary);
    }

    span{
        color: get-color(dark) !important;
        font-size: 12px;
        display: initial;
    }

    .select2-container--default .select2-selection--multiple {
        background-color: get-color(light);
        border: 1px solid get-color( primary);
        border-radius: 4px;
        cursor: text;
        padding-bottom: 2px;
        padding-right: 3px;
        position: relative;
        min-height: 50px;
        max-height: 50px;
        overflow: auto;
    }

    .select2-container .select2-selection--multiple .select2-selection__rendered {
        display: inline-table;
        position: inherit;
        margin: 0;
    }

    .select2-container--default .select2-search--inline .select2-search__field {
        margin: 0;
        padding: 0;
    }

    textarea.select2-search__field {
        position: absolute;
        top: 19px;
        left: 10px;
        width: 80% !important;
    }

    .field--submit {
        color: get-color(light);
        border-radius: 4px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        i {
            z-index: 8;
            top: 18px;
            left: 21px;
            color: get-color(secondary);
            position: absolute;
        }

        input[type="submit"] {
            border-radius: 4px;
            border: none;
            background: get-color(primary);
            color: get-color(light);
            position: relative;
            text-transform: uppercase;
            padding: 16px;
            width: 100%;
        }
    }

    .menu-filter__item {
        position: relative;
    }

    .menu-filter {
        display: flex;
        align-items: center;
    }

    .drop {
        display: none;
    }

    .menu-toggle-button {
        color: get-color(dark);
        height: 50px;

        i {
            margin-left: 20px;
        }

        &.range{
            background: get-color(light);
        }
    }

    .form-multi-select.show .form-multi-select-search[size] {
        display: none;
    }

    .form-multi-select-option.form-multi-selected::before {
        background-color: get-color(primary);
        border-color: get-color(dark);
    }

    .form-multi-select.menu-toggle-button.form-multi-select-multiple.form-multi-select-with-cleaner.show {
        box-shadow: none;
    }

    .navigation-menu {
        position: absolute;
        background: get-color(light);
        max-width: 230px;
        width: 100%;
        z-index: 9;
        border-radius: 4px;
        top: 58px;
        box-shadow: 0px 0px 4px 0px get-color( primary);
        padding: 10px 20px;
        color: get-color(dark);
        ul {
            padding: 0;
            margin: 0;
            max-height: 200px;
            overflow: auto;
        }

        li {
            list-style: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .range-min,
        .range-max {
            label {
                display: block;
                text-align: left;
                margin-bottom: 10px;
            }
        }

        .minAmount,
        .maxAmount,.minAmounts,
        .maxAmounts {
            margin: 14px 0;
        }
    }

    .menu-item {
        width: 100%;
        display: block;
        text-align: left;
        border-radius: 4px;
        padding: 8px;
        font-size: 14px;
    }

    .drop.active {
        display: block;
    }

    .aplicar,.aplicars  {
        border-radius: 4px;
        border: none;
        background: get-color(primary);
        color: get-color(light);
        position: relative;
        text-transform: uppercase;
        padding: 16px;
        width: 100%;
    }

    span.ui-slider-handle.ui-corner-all.ui-state-default {
        border-radius: 100%;
        background: get-color(primary);
        border: none;
    }

    .ui-widget-header {
        background: get-color(primary);
    }

    .ui-slider-horizontal {
        display: none;
        height: 7px;

        @include media (get-bp(desktop)) {
            display: block;
        }

        .ui-slider-handle {
            top: -0.6em;
            margin-left: -0.6em;
            width: 1.5em;
            height: 1.5em;
        }
    }
}
