.news {
    margin-top: 80px;
    background: get-color(light);
    padding: 50px 0;

    .title {
        h2 {
            display: block;
            color: get-color(secondary);
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 0;
            text-align: left;
        }

        p {
            display: block;
            font-size: 14px;
            color: get-color(grey-light);
            max-width: 700px;
            text-align: left;
        }

        span {
            display: block;
            font-size: 14px;
            color: get-color(grey-light);
            text-transform: uppercase;
            font-weight: 600;
            text-align: left;
        }
    }

    .news__content {
        margin-top: 48px;
        display: grid;
        grid-column-gap: 32px;

        @include media(get-bp(tablet-portrait)) {
            grid-template-columns: 1fr 1fr;
        }

        @include media(get-bp(desktop)) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
