.our-solutions {
    &.title {
        background: #fa7f4b;
        padding: 70px 0;
        margin-bottom: 80px;
    }

    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        color: get-color(light);

        @include media (get-bp(desktop)) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        li {
            list-style: none;

            h2 {
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize
            }

            p {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
            }
        }
    }

    .our-solutions__item {
        border-radius: 8px;
        position: relative;
        cursor: pointer;

        .tag {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: #444;
            padding: 6px;
            border-radius: 24px;
            font-size: 12px;
            font-weight: 700;
            width: 120px;
            text-align: center;
            color: get-color(light);
        }
    }

    .our-solutions__image {
        width: 100%;
        height: 300px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 10px;
        }
    }

    .our-solutions__info {
        background-color: get-color(light);
        padding: 16px 10px;
        height: 150px;
        box-sizing: content-box;
        display: flex;
        align-items: center;
    }

    .our-solutions__text {
        color: get-color(dark);
        text-align: center;
        overflow: hidden;
        max-height: 213px;
        font-weight: 400;
        font-size: 13px;
        width: 100%;

        h2 {
            font-size: 20px;
            text-align: left;
            height: 50px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }

        p {
            text-align: left;
            font-size: 14px;
            height: 60px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }

    .swiper-button-next.so,
    .swiper-button-prev.so {
        background: unset;
        color: #fff;
    }
}
