.page-questions {
    padding-top: 13rem;

    @include media(get-bp(desktop)) {
        padding-top: 14rem;
    }

    .questions {
        padding: 60px 0;

        h2 {
            font-size: 32px;
            margin-bottom: 40px;
        }
    }

    .questions-items {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid get-color(terciary);

        .header {
            margin-bottom: 20px;

            .subjects {
                display: flex;
                align-items: center;
            }
        }
    }

    .questions {
        .questions-items {
            .subjects {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                span {
                    background-color: transparent;
                    color: get-color(dark);
                    border-radius: 4px;
                    display: inline-block;
                    text-align: center;
                    border: 1px solid get-color(dark);
                    padding: 5px 10px;
                }

                h2 {
                    font-size: 16px;
                    background: get-color(grey-lighter);
                    width: 100%;
                    padding: 10px;
                    border-radius: 4px;
                    margin-bottom: 0;
                }
            }

            .infos-quest {
                border-bottom: 1px solid get-color(grey-lighter);

                li {
                    list-style: none;
                }

                h2 {
                    margin: 0;
                    font-size: 16px;
                    color: get-color(dark);
                }

                strong{
                    color: get-color(terciary);
                }

                .period {
                    color: get-color(grey);
                    font-size: 14px;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    padding-bottom: 10px;

                    li {
                        margin-right: 20px;

                        p {
                            margin: 0;
                            font-size: 14px;

                            strong {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .in-content-question {
                .in-question-text {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;

                    .text {
                        margin-bottom: 20px;

                        h2 {
                            font-size: 18px;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 14px;
                            margin-bottom: 10px;
                        }
                    }

                    .in-question-image {
                        img {
                            border-radius: 8px;
                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        }
                    }
                }

                .in-question-options {
                    .details {
                        margin-bottom: 20px;

                        h2 {
                            font-size: 18px;
                            margin-bottom: 10px;
                        }
                    }

                    .form-group {
                        h2 {
                            font-size: 16px;
                            margin-bottom: 10px;
                        }

                        .in-radio-button {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                            padding: 10px;

                            input[type="radio"] {
                                margin-right: 10px;
                                display: none;

                                &:checked~.in-option-item {
                                    background-color: get-color(terciary);
                                }
                            }

                            &.correct{
                                background: #c4f1d6;
                                border-radius: 6px;

                                input[type="radio"]:checked + .in-option-item {
                                    background-color: get-color(terciary);
                                    color: #fff;
                                }
                            }

                            .in-option-item {
                                font-weight: bold;
                                margin-right: 10px;
                                border: 1px solid get-color(terciary);
                                border-radius: 50%;
                                color: get-color(terciary);
                                display: inline-block;
                                font-size: 12px;
                                font-weight: 600;
                                height: 32px;
                                line-height: 30px;
                                margin-right: 15px;
                                min-width: 32px;
                                text-align: center;
                                text-transform: uppercase;
                                transition: .15s ease-in-out;
                                width: 32px;
                            }

                            input:checked~.in-option-item {
                                background-color: get-color(terciary);
                                color: #fff
                            }

                            .in-item-enum {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .mark{
                    background-color: get-color(terciary);
                    color: get-color(light) !important;
                }

                .in-question-buttons {
                    display: flex;
                    margin-top: 20px;

                    .btn-primary {
                        padding: 10px 20px;
                        font-size: 16px;
                        color: white;
                        background: get-color(terciary);
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;

                        &:disabled {
                            opacity: .5;
                            cursor: no-drop;
                        }
                    }
                }

                .accordion.js-accordion {
                    background: get-color(terciary);
                    border: none;
                    border-bottom: 1px solid #cecece;
                    border-radius: 4px;
                    margin-top: 40px;
                }

                .accordion__header.js-accordion-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 1;
                    margin: 0;
                    letter-spacing: 0.08em;
                    margin-bottom: 0px;
                    font-size: 14px;
                    color: get-color(light);
                    padding: 10px;
                }

                .accordion__content {
                    display: none;
                    color: get-color(light);
                }

                .accordion__header {
                    cursor: pointer;
                }

                .accordion__content.js-accordion-content.active {
                    display: block !important;
                    margin-top: 30px;
                    font-size: 13px;
                }

                .accordion__header.js-accordion-header.active  i.fas.fa-chevron-down{
                    transform: rotate(180deg);
                }
            }
        }
    }
}
