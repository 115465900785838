.slide-cases {
    margin-top: -49px;
    overflow: hidden;

    .container {
        position: relative;

        &::after {
            content: " ";
            width: 2px;
            height: 100%;
            background-color: get-color(primary);
            position: absolute;
            left: 0;
            top: 13px;
        }
    }

    .case {
        display: grid;
        grid-template-columns: 1fr;

        @include media(get-bp(tablet-landscape)) {
            grid-template-columns: repeat(2, 1fr);
        }

        .img {
            clip-path: polygon(0 7%, 100% 0%, 100% 93%, 0% 100%);
            height: 700px;

            img {
                display: block;
                width: 100%;
                height: 700px;
                object-fit: cover;
                object-position: top;
            }
        }

        .text {
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            span {
                text-transform: uppercase;
                font-size: 16px;
            }

            h2 {
                font-size: 32px;
                margin-bottom: 20px;
                font-weight: 400;

                strong {
                    font-weight: 700;
                }
            }

            p {
                font-size: 16px;
            }
        }

        .btn {
            display: flex;
            align-items: center;
            background: get-color(light);
            width: 200px;
            justify-content: left;
            color: get-color(dark);
            border-radius: 4px;
            margin-top: 20px;
            list-style: 17px;

            @include media(get-bp(desktop)) {
                margin-top: 30px;
            }

            p {
                margin: 0;
                font-size: 16px;
                text-transform: uppercase;
            }

            i {
                transform: rotate(-45deg);
                margin-left: 10px;
                transition: 400ms;
                color: get-color(dark);
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: max-content;
        background: none;
        z-index: 9999999;

        @include media (get-bp(tablet-landscape)) {
            display: block;
        }

        i {
            font-size: 24px;
            color: get-color(primary);
            border: 1px solid;
            padding: 10px 14px;
            border-radius: 30px;
        }
    }

    .swiper-button-disabled {
        display: none;
    }
}

.frases{
    padding: 100px 0;

    .container {
        position: relative;

        &::after {
            content: " ";
            width: 2px;
            height: 755px;
            background-color: get-color(primary);
            position: absolute;
            left: 0;
            top: -107px;
        }
    }

    h3 {
        text-transform: uppercase;
        font-size: 20px;
        padding-top: 30px;
        margin-bottom: 30px;
        font-weight: 300;
        position: relative;

        &:first-child {
            &::after {
                display: none;
            }
        }

        &::after {
            content: " ";
            width: 100%;
            height: 2px;
            background-color: get-color(primary);
            position: absolute;
            left: -30px;
            top:  0px;

            &:first-child {
                display: none;
            }
        }

        @include media(get-bp(desktop)) {
            font-size: 64px;
        }

        strong {
            font-weight: 700;
        }
    }

    .swiper-button-next.so,
    .swiper-button-prev.so {
        background: unset;
        color: #111;
    }
    .btn {
        display: block;
        width: 100%;
        max-width: 150px;
        padding: 10px;
        margin-top: 30px;
        background-color: transparent;
        border: 1px solid get-color(primary);
        color: get-color(primary);
        text-align: center;
        margin: 40px auto 0;

        &:hover {
            background-color: get-color(secondary);
            border: 1px solid get-color(secondary);
            color: get-color(primary);
        }
    }
}
