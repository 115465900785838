.bar-info-enterprises {
    background: get-color(dark);
    padding: 10px 20px;
    overflow: hidden;

    .swiper-wrapper {
        position: relative;
        transition-timing-function: linear;
    }

    .swiper-slide {
        margin: 0 !important;
    }

    .bar-info-enterprises-slide {
        width: 95%;
        margin: auto;
        display: flex;
        align-items: center;

        @include media(get-bp(desktop)) {
            width: 1300px;
        }
    }

    .bar-info-enterprises-buttons {
        background: get-color(secondary);
        padding: 0 10px;
        display: flex;
        align-items: center;
        z-index: 9;
        position: absolute;
        right: 0px;

        @include media(get-bp(tablet-portrait)) {
            right: 0px;
            padding: 0 20px;
        }

        .bar-info-enterprises-button-prev,
        .bar-info-enterprises-button-next {
            font-size: 23px;
            color: get-color(light);
        }

        .bar-info-enterprises-button-prev {
            margin-right: 20px;
        }
    }

    .bar-info-item {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 250px;
        justify-content: center;

        .name {
            display: flex;
            align-items: center;
            margin-right: 16px;

            h2 {
                margin: 0;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                color: get-color(light);
                white-space: nowrap;
                margin-left: 20px;
            }

            p {
                margin: 0;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 30px;
                margin-left: 10px;
                color: get-color(light);
            }
        }

        .icon-item {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
        }

    }
}
