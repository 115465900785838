.news__item {
    width: 100%;

    a:hover {
        .news__image {
            img {
                scale: 1.5;
            }
        }
    }

    .news__image {
        border-radius: 4px;
        overflow: hidden;
        width: 100%;
        height: 144px;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            transition: 200ms;
        }
    }

    .news__info {
        padding: 24px 8px;
        position: relative;
        display: grid;
        grid-row-gap: 8px;

        h3 {
            font-weight: 600;
            font-size: 14px;
            font-family: $font-family-primary;
            margin: 0;
            color: get-color(dark);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            height: 36px;
            -webkit-box-orient: vertical;
        }

        p {
            font-weight: 400;
            font-size: 13px;
            color: get-color(dark);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            height: 59px;
            -webkit-box-orient: vertical;
        }

        button {
            color: get-color(dark);
            font-weight: 500;
            font-size: 14px;
            padding: 0;
            background-color: transparent;
            border: none;
            display: block;
            width: max-content;
        }
    }

    .news__tag {
        display: block;
        width: max-content;
        padding: 4px 8px;
        border-radius: 100px;
        color: get-color(light);
        font-size: 12px;
        font-weight: 500;
        font-family: $font-family-primary;
        text-transform: uppercase;
        position: absolute;
        top: -14px;
        left: 8px;
        background-color: get-color(secondary-darker);

        &.blue {
            background-color: get-color(primary);
        }

        &.orange {
            background-color: get-color(secondary);
        }
    }
}
