.banner__default {
    position: relative;

    img {
        display: block;
        width: 100%;
        object-fit: cover;
        position: relative;
    }

    .text{
        position: absolute;

        h2{
            font-size: 32px;
            color: get-color(light);
            text-transform: uppercase;
            max-width: 646px;
            text-align: center;

            @include media (get-bp(desktop)) {
                font-size: 64px;
            }
        }
    }
    .banner__desktop {
        display: none;
    }

    .banner__tablet {
        display: none;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 800px;
        object-fit: cover;
    }

    img {
        display: block;
        width: 100%;
        height: 800px;
        object-fit: cover;
    }

    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    iframe {
        display: block;
        width: 100%;
        height: 800px;
    }

    .instagram-media.instagram-media-rendered {
        width: 250px !important;
        max-height: 800px;
    }

    @include media (get-bp(tablet-portrait)) {
        .banner__mobile {
            display: none;
        }

        .banner__tablet {
            display: block;
        }
    }

    @include media (get-bp(desktop)) {
        .banner__tablet {
            display: none;
        }

        .banner__desktop {
            display: block;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: max-content;
        background: none;
        z-index: 9999999;

        @include media (get-bp(tablet-landscape)) {
            display: block;
        }

        i {
            font-size: 24px;
            color: get-color(primary);

            @include media (get-bp(desktop)) {
                font-size: 52px;
            }
        }
    }

    .swiper-button-disabled {
        display: none;
    }

    .swiper-pagination {

        @include media (get-bp(desktop)) {
            display: none;
        }
    }

    //paginacao estilo bola
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border: 2px solid get-color(primary);
        transition: 400ms;
    }

    //paginacao estilo linha
    // .swiper-pagination-bullet {
    //     width: 30px;
    //     height: 6px;
    //     border-radius: 10px;
    //     background-color: get-color(primary);
    //     transition: 400ms;
    // }
}
