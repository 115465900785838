.tabs-container {
    .tab-group {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        font-size: 16px;
    }

    .tab-content {
        padding: 20px;
        border: none;
        background-color: get-color(light);
    }

    .tab {
        padding: 5px 20px;
        background-color: transparent;
        cursor: pointer;
        border: none;
        margin-bottom: 2px;

        &.is-active {
            border-bottom: 2px solid get-color(primary);
        }
    }


    .content {
        display: none;
    }

    .content.is-active {
        display: block;
    }
}
