.news {
    margin-top: 80px;
    padding: 50px 0;
}

.default-title p {
    text-transform: uppercase;
    color: #58BFDF;
    font-weight: 700;
    font-size: 14px;
}

.news__content {
    margin-top: 48px;
    display: grid;
    grid-column-gap: 32px;

    @include media(get-bp(tablet-portrait)) {
        grid-template-columns: 1fr 1fr;
    }

    @include media(get-bp(desktop)) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.blog-listing {
    margin:  0px 0 30px 0;

    .pagination {
        margin: 30px 0;
    }
}

.news{
    background: #cecece;

    .default-title h2 {
        color: #000;
    }
}
